//

export default {
  props: {
    value: {
      type: Number,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    forcePage: {
      type: Number,
    },
    clickHandler: {
      type: Function,
      default: () => { },
    },
    pageRange: {
      type: Number,
      default: 3,
    },
    marginPages: {
      type: Number,
      default: 1,
    },
    prevText: {
      type: String,
      default: 'Prev',
    },
    nextText: {
      type: String,
      default: 'Next',
    },
    breakViewText: {
      type: String,
      default: '…',
    },
    containerClass: {
      type: String,
    },
    pageClass: {
      type: String,
      default: 'page-item',
    },
    pageLinkClass: {
      type: String,
      default: 'page-link',
    },
    prevClass: {
      type: String,
      default: 'flex items-center text-xs  px-2 text-primary-light rounded-md',
    },
    prevLinkClass: {
      type: String,
    },
    nextClass: {
      type: String,
      default: 'flex items-center text-xs  px-2 text-primary-light rounded-md',
    },
    nextLinkClass: {
      type: String,
    },
    breakViewClass: {
      type: String,
    },
    breakViewLinkClass: {
      type: String,
    },
    activeClass: {
      type: String,
      default: 'active',
    },
    disabledClass: {
      type: String,
      default: 'disabled',
    },
    noLiSurround: {
      type: Boolean,
      default: false,
    },
    firstLastButton: {
      type: Boolean,
      default: false,
    },
    firstButtonText: {
      type: String,
      default: 'First',
    },
    lastButtonText: {
      type: String,
      default: 'Last',
    },
    hidePrevNext: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      innerValue: 1,
    }
  },
  computed: {
    selected: {
      get() {
        return this.value || this.innerValue
      },
      set(newValue) {
        this.innerValue = newValue
      },
    },

    pages () {
      const items = {}
      if (this.pageCount <= this.pageRange) {
        for (let index = 0; index < this.pageCount; index++) {
          const page = {
            index,
            content: `${index + 1}`,
            selected: index === (this.selected - 1),
          }
          items[index.toString()] = page
        }
      }
      else {
        const halfPageRange = Math.floor(this.pageRange / 2)
        const setPageItem = (index) => {
          const page = {
            index,
            content: `${index + 1}`,
            selected: index === (this.selected - 1),
          }
          items[index] = page
        }

        const setBreakView = (index) => {
          const breakView = {
            disabled: true,
            breakView: true,
          }
          items[index] = breakView
        }
        // 1st - loop thru low end of margin pages
        for (let i = 0; i < this.marginPages; i++)
          setPageItem(i)

        // 2nd - loop thru selected range
        let selectedRangeLow = 0
        if (this.selected - halfPageRange > 0)
          selectedRangeLow = this.selected - 1 - halfPageRange

        let selectedRangeHigh = selectedRangeLow + this.pageRange - 1
        if (selectedRangeHigh >= this.pageCount) {
          selectedRangeHigh = this.pageCount - 1
          selectedRangeLow = selectedRangeHigh - this.pageRange + 1
        }
        for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.pageCount - 1; i++)
          setPageItem(i)

        // Check if there is breakView in the left of selected range
        if (selectedRangeLow > this.marginPages)
          setBreakView(selectedRangeLow - 1)

        // Check if there is breakView in the right of selected range
        if (selectedRangeHigh + 1 < this.pageCount - this.marginPages)
          setBreakView(selectedRangeHigh + 1)

        // 3rd - loop thru high end of margin pages
        for (let i = this.pageCount - 1; i >= this.pageCount - this.marginPages; i--)
          setPageItem(i)
      }
      return items
    }
  },
  methods: {
    handlePageSelected(selectedPage) {
      if (this.selected === selectedPage)
        return
      this.innerValue = selectedPage
      this.$emit('input', selectedPage)
      this.clickHandler(this.selected)
    },

    prevPage() {
      if (this.selected <= 1)
        return
      this.handlePageSelected(this.selected - 1)
    },

    nextPage() {
      if (this.selected >= this.pageCount)
        return
      this.handlePageSelected(this.selected + 1)
    },
    firstPageSelected() {
      return this.selected === 1
    },

    lastPageSelected() {
      return (this.selected === this.pageCount) || (this.pageCount === 0)
    },

    selectFirstPage() {
      if (this.selected <= 1)
        return
      this.handlePageSelected(1)
    },
    selectLastPage() {
      if (this.selected >= this.pageCount)
        return
      this.handlePageSelected(this.pageCount)
    }
  },
  onBeforeUpdate() {
    if (this.forcePage !== undefined)
      this.innerValue = this.forcePage
  }
}

