//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations  } from 'vuex'
import TheButton from './TheButton.vue'
export default {
  components: { TheButton },
  props: {
    data: {
      type: Object,
      required: true
    },
    selectTheme: {
      type: Boolean,
      default: false
    },
    preview: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      hover: false,
      theme: localStorage.getItem('theme')
    }
  },
  computed: {
    themeParam () {
      return this.$store.state.invitation.theme
    },
    themeSrc() {
      const imagePath = this.$getImageFormat(this.data.attributes?.thumbnail?.data?.attributes, true );
      if (imagePath) {
        return  imagePath
      }
      return false
    },
    currentCategory (){
      return this.data.attributes?.invitation_category?.data?.attributes?.name?.toLowerCase()
    },
  },

  watch: {
    theme(val){
      this.selectedTheme(JSON.parse(val))
    }
  },

  mounted() {
    if (localStorage.theme) {
      this.theme = localStorage.theme;
      this.selectedTheme(JSON.parse(this.theme))
    }
    window.addEventListener("storage", this.onStorageUpdate);
  },

  methods: {
    ...mapMutations('invitation', ['setTheme', 'setThemeCategory']),
    onHover() {
      this.hover = true
    },
    selectedTheme(theme) {
        this.setTheme(theme.id)
        this.setThemeCategory(theme.attributes.invitation_category.data.attributes.name)
    },
    goTo () {
      if (this.preview) {
        const routeData = this.$router.resolve( { path: `/preview/${this.data.attributes.slug}` });
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push(`${this.data.attributes.slug}`)
      }
    },
    onStorageUpdate(event) {
      if (event.key === "theme") {
        this.theme = event.newValue;
      }
    }
  },

}
