//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex'
export default {
  props: {
    params: {
      type: Object,
      default: () => ({
        date: '',
        category: '',
        page: null,
      }),
    },
    selectTheme: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      themes: [],
      loading: false,
      pagination: {
        page: 1,
        total: 10,
      },
    }
  },
  fetchOnServer: false,
  async fetch() {
    await this.$theme
      .getThemes({ ...this.params, page: this.pagination.page})
      .then((res) => {
        this.themes = res.data.data
        this.pagination.total = res.data.meta.pagination.pageCount
        this.$emit('loaded', this.themes)
        if (this.themeParam === '') this.selectedTheme(this.themes[0])
      })
      .catch((err) => {
        throw err
      })
  },
  computed: {
    ...mapState({
      themeParam: (state) => state.invitation.theme
    }),
  },
  watch: {
    params: {
      handler(val) {
        this.$fetch()
      },
      deep: true,
    },
  },
  mounted() {
    if (this.$route.query.theme) {
      this.setTheme(this.$route.query.theme)
    }
  },
  methods: {
    ...mapMutations('invitation', ['setTheme', 'setThemeCategory']),
    selectedTheme(theme) {
      this.setTheme(theme.id)
      this.setThemeCategory(theme.attributes.invitation_category.data.attributes.name)
    },
    onChangePage(page) {
      this.pagination.page = page
    },
  },
}
