//
//
//
//
//

export default {
  props: {
    h: {
      default: 'h-2',
      type: String
    },
    w: {
      default: 'w-full',
      type: String
    }
  }
}
