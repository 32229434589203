import { render, staticRenderFns } from "./ThemeList.vue?vue&type=template&id=128fab35&"
import script from "./ThemeList.vue?vue&type=script&lang=js&"
export * from "./ThemeList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingTheme: require('/opt/build/repo/components/Loading/Theme.vue').default,ThemeCard: require('/opt/build/repo/components/ThemeCard.vue').default,Pagination: require('/opt/build/repo/components/Pagination.vue').default})
